import ee from '../ee'

function initAccordion(container = document) {
  const accordions = container.querySelectorAll('.accordion.js-accordion')

  accordions.forEach((accordion) => {
    const allowMultiplePanelsOpen =
      accordion.getAttribute('data-allow-multiple') === 'true'

    accordion
      .querySelectorAll('.accordion__button')
      .forEach((button, index) => {
        button.addEventListener('click', () => {
          const panel = button.nextElementSibling
          const isPanelOpen = panel.style.maxHeight !== '0px'
          const openIcon = button.querySelector('.accordion__open-icon')
          const closedIcon = button.querySelector('.accordion__closed-icon')

          if (allowMultiplePanelsOpen) {
            if (isPanelOpen) {
              panel.style.maxHeight = '0px'
              openIcon.style.display = 'none'
              closedIcon.style.display = 'block'
            } else {
              panel.style.maxHeight = `${panel.scrollHeight}px`
              openIcon.style.display = 'block'
              closedIcon.style.display = 'none'
            }
          } else {
            accordion
              .querySelectorAll('.accordion__panel')
              .forEach((otherPanel, otherIndex) => {
                if (otherIndex !== index) {
                  otherPanel.style.maxHeight = '0px'
                  otherPanel.previousElementSibling.querySelector(
                    '.accordion__open-icon'
                  ).style.display = 'none'
                  otherPanel.previousElementSibling.querySelector(
                    '.accordion__closed-icon'
                  ).style.display = 'block'
                }
              })

            if (isPanelOpen) {
              panel.style.maxHeight = '0px'
              openIcon.style.display = 'none'
              closedIcon.style.display = 'block'
            } else {
              panel.style.maxHeight = `${panel.scrollHeight}px`
              openIcon.style.display = 'block'
              closedIcon.style.display = 'none'
            }
          }
        })

        // Initialize the correct icon display state on page load
        const panel = button.nextElementSibling
        const openIcon = button.querySelector('.accordion__open-icon')
        const closedIcon = button.querySelector('.accordion__closed-icon')
        if (panel.style.maxHeight === '0px') {
          openIcon.style.display = 'none'
          closedIcon.style.display = 'block'
        } else {
          openIcon.style.display = 'block'
          closedIcon.style.display = 'none'
        }
      })
  })
}

ee.addListener('initComponents', initAccordion)
