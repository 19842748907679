import { initGsap } from './gsap'
import { initMqListener } from './breakpoint'
import ee from './ee'

initGsap()

import './components/accordion'
import './components/carousel'
import './components/map-intro'
import './components/primary-nav'
import './components/scroll-to'
import './components/site-head'
import './components/stickers'

document.addEventListener('DOMContentLoaded', () => {
  initMqListener()
  ee.emit('initComponents')
})
