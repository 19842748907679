import $ from 'jquery'
import 'slick-carousel'
import ee from '../ee'

let activeCarousels = []

function refreshCarousels(container = document) {
  const carouselEls = container.querySelectorAll('.js-carousel')

  Array.from(carouselEls).forEach((el) => {
    el.slick.refresh()
  })
}

function init(container = document) {
  const carouselEls = container.querySelectorAll('.js-carousel')

  Array.from(carouselEls).forEach((el) => {
    switch (el.dataset.carouselType) {
      case 'cards':
        $(el).slick({
          dots: false,
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          touchThreshold: 10,
          infinite: false,
          slide: '.js-carousel-slide',
          rows: 0,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        })
        break

      case 'image':
        const carousel = $(el).slick({
          dots: false,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          touchThreshold: 10,
          slide: '.js-carousel-slide',
          rows: 0,
        })

        carousel.on('afterChange', function (event, slick, currentSlide) {
          const slideElement = slick.$slides.get(currentSlide) // get current slide DOM element
          const caption = slideElement.dataset.caption // get caption from data attribute
          const captionElement = $(el).parent().find('.carousel-caption')[0] // find the caption element
          captionElement.textContent = caption // set caption
        })

        const firstSlideElement = carousel.find('.slick-current')[0] // get the first slide DOM element
        const firstSlideCaption = firstSlideElement.dataset.caption // get caption from data attribute
        const firstCaptionElement = $(el).parent().find('.carousel-caption')[0] // find the caption element
        firstCaptionElement.textContent = firstSlideCaption

        break
    }
    activeCarousels.push(el)
  })
}

function destroy() {
  Array.from(activeCarousels).forEach((el) => {
    $(el).slick('unslick')
  })

  activeCarousels = []
}

ee.addListener('initComponents', init)
ee.addListener('destroyComponents', destroy)
ee.addListener('refreshCarousels', refreshCarousels)
