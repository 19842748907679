import ee from '../ee'
import { gsap } from 'gsap'

function animateStickers() {
  const elStickerMap = document.querySelector('.sticker-map svg')
  const elStickerDiscussion = document.querySelector('.sticker-discussion svg')
  const elStickerSurvey = document.querySelector('.sticker-survey svg')

  if (!elStickerMap || !elStickerDiscussion || !elStickerSurvey) return

  const delay = -0.9
  const duration = 1.5
  const ease = 'expo.out'
  const rotate = 4

  const stickerAnimation = gsap
    .timeline({ repeat: -1, delay: 1, repeatDelay: 2 })
    .to(elStickerMap, {
      duration,
      ease,
      keyframes: [{ rotate: -rotate }, { rotate }, { rotate: 0 }],
    })
    .to(elStickerDiscussion, {
      delay,
      duration,
      ease,
      keyframes: [{ rotate: -rotate }, { rotate }, { rotate: 0 }],
    })
    .to(elStickerSurvey, {
      delay,
      duration,
      ease,
      keyframes: [{ rotate }, { rotate: -rotate }, { rotate: 0 }],
    })
}

ee.addListener('initComponents', animateStickers)
