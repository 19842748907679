import mqListener from 'media-query-listener'
import ee from './ee'

let currentBreakpoint = null

export function initMqListener() {
  mqListener.on('change', (breakpoint) => {
    currentBreakpoint = breakpoint
    ee.emit('breakpointChange', breakpoint)
  })
}

export function getCurrentBreakpoint() {
  return currentBreakpoint
}
