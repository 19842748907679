export function getScrollbarWidth() {
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll'
  document.body.appendChild(outer)

  const inner = document.createElement('div')
  outer.appendChild(inner)

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

  outer.parentNode.removeChild(outer)

  return scrollbarWidth
}

export function addScrollLock() {
  const bodyElement = document.querySelector('body')
  const siteHeadElement = document.querySelector('.site-head')

  bodyElement.style.overflow = 'hidden'
  bodyElement.style.paddingRight = `${getScrollbarWidth()}px`
  siteHeadElement.style.right = `${getScrollbarWidth()}px`
}

export function removeScrollLock() {
  const bodyElement = document.querySelector('body')
  const siteHeadElement = document.querySelector('.site-head')

  bodyElement.style.overflow = 'auto'
  bodyElement.style.paddingRight = '0'
  siteHeadElement.style.right = '0'
}

export function trapFocus(element) {
  const focusableElements = element.querySelectorAll(
    'a[href], button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
  )
  const firstFocusableElement = focusableElements[0]
  const lastFocusableElement = focusableElements[focusableElements.length - 1]

  firstFocusableElement.focus()

  element.addEventListener('keydown', (event) => {
    if (
      event.key === 'Tab' &&
      !event.shiftKey &&
      document.activeElement === lastFocusableElement
    ) {
      event.preventDefault()
      firstFocusableElement.focus()
    }
    if (
      event.key === 'Tab' &&
      event.shiftKey &&
      document.activeElement === firstFocusableElement
    ) {
      event.preventDefault()
      lastFocusableElement.focus()
    }
  })
}
