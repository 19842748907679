import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import ee from '../ee'

class SiteHead {
  constructor() {
    this.el_head = document.querySelector('.js-site-head')
    this.animation = null
  }

  hide() {
    this.animation?.reverse()
  }

  init() {
    this.animation = gsap
      .from(this.el_head, {
        yPercent: -100,
        paused: true,
        duration: 0.2,
      })
      .progress(1)

    ScrollTrigger.create({
      start: `${window.innerHeight * 0.6}px top`,
      end: 99999,
      onUpdate: ({ direction }) => {
        direction > 0 ? this.hide() : this.show()
      },
    })
  }

  show() {
    this.animation?.play()
  }
}

export const siteHead = new SiteHead()

ee.addListener('initComponents', () => siteHead.init())
