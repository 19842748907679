import ee from '../ee'
import { addScrollLock, removeScrollLock, trapFocus } from '../helpers/overlay'

function closeMapIntro() {
  const mapIntroEl = document.querySelector('.js-map-intro')

  mapIntroEl.style.opacity = '0'
  mapIntroEl.style.pointerEvents = 'none'
  removeScrollLock()
}

function init() {
  const mapIntroEl = document.querySelector('.js-map-intro')
  const mapIntroStartCtaEl = document.querySelector('.js-map-intro-start-cta')
  const mapIntroOverlayEl = document.querySelector('.js-map-intro-overlay')

  if (!mapIntroEl || !mapIntroStartCtaEl || !mapIntroOverlayEl) return

  addScrollLock()
  trapFocus(mapIntroEl)

  mapIntroStartCtaEl.addEventListener('click', () => {
    closeMapIntro()
  })

  // Add this event listener
  mapIntroOverlayEl.addEventListener('click', (event) => {
    event.stopPropagation()
    closeMapIntro()
  })

  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      event.preventDefault()
      closeMapIntro()
    }
  })
}

ee.addListener('initComponents', init)
