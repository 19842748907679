import ee from '../ee'

let contentTop
const ctaDiv = document.getElementById('map-cta')

function updateContentTop() {
  const contentElement = document.querySelector('.content-start')
  contentTop = contentElement.offsetTop - 96
}

function scrollToContent() {
  window.scrollTo({
    top: contentTop,
    behavior: 'smooth',
  })
}

function checkScrollPosition() {
  if (window.scrollY >= contentTop - window.innerHeight / 2) {
    ctaDiv.style.transform = 'translateY(100%)'
  } else {
    ctaDiv.style.transform = 'translateY(0)'
  }
}

function init(container = document) {
  const ctaButton = document.getElementById('map-cta-button')

  if (!ctaButton) return

  ctaButton.addEventListener('click', (e) => {
    e.preventDefault()
    scrollToContent()
  })

  // Listen for scroll and resize events
  window.addEventListener('scroll', checkScrollPosition)
  window.addEventListener('resize', updateContentTop)

  // Initial calculation
  updateContentTop()
}

ee.addListener('initComponents', init)
