import ee from '../ee'
import { addScrollLock, removeScrollLock, trapFocus } from '../helpers/overlay'

function toggleNav(burger) {
  const isOpen = burger.getAttribute('data-is-open') === 'true'
  burger.setAttribute('data-is-open', !isOpen)
  burger.classList.toggle('site-head__burger--open')
  burger.setAttribute('aria-expanded', !isOpen)

  const primaryNav = document.querySelector('.primary-nav')
  const primaryNavMenu = document.querySelector('.primary-nav__menu')
  const primaryNavOverlay = document.querySelector('.primary-nav__overlay')
  if (isOpen) {
    primaryNav.classList.remove('open')
    primaryNavMenu.style.transform = 'translateX(100%)'
    primaryNavOverlay.style.opacity = '0'
    primaryNavOverlay.style.pointerEvents = 'none'
    removeScrollLock()
  } else {
    primaryNav.classList.add('open')
    primaryNavMenu.style.transform = 'translateX(0)'
    primaryNavOverlay.style.opacity = '1'
    primaryNavOverlay.style.pointerEvents = 'auto'
    addScrollLock()
    trapFocus(primaryNavMenu)
  }
}

function init(container = document) {
  const burger = container.querySelector('#hamburger-button')
  const primaryNavOverlay = document.querySelector('.primary-nav__overlay')

  if (!burger || !primaryNavOverlay) return

  burger.addEventListener('click', () => {
    toggleNav(burger)
  })

  primaryNavOverlay.addEventListener('click', (event) => {
    event.stopPropagation()
    toggleNav(burger)
  })

  burger.addEventListener('keydown', (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      toggleNav(burger)
    }
  })

  document.addEventListener('keydown', (event) => {
    if (
      event.key === 'Escape' &&
      burger.getAttribute('data-is-open') === 'true'
    ) {
      event.preventDefault()
      toggleNav(burger)
    }
  })
}

ee.addListener('initComponents', init)
